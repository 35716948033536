<template>
  <BCard>
    <h4 class="font-bold text-black text-2xl">
      {{ title }}
    </h4>
    <div class="flex mt-2">
      <BCol lg="5">
        <BCard class="border m-0">
          <div class="flex items-center justify-between">
            <div class="flex">
              <span class="text-black">Saldo Komerce</span>
              <img
                v-b-tooltip.hover.top="`Saldo Komerce di Xendit`"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
              >
              <span class="ml-[5px]">:</span>
              <BSpinner
                v-if="loadingBalance"
                small
                class="ml-1"
                variant="primary"
              />
              <div
                v-else
                class="text-[#08A0F7] text-xl font-semibold ml-[5px]"
              >
                {{ IDR(balance) }}
              </div>
            </div>
            <div
              class="bg-[#F95031] rounded-lg p-[5px] cursor-pointer"
              @click="handleRefreshBalance"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/rotate-left-1.svg"
                alt="Komerce"
              >
            </div>
          </div>
          <div class="flex">
            <span class="text-black">Total Request Penarikan</span>
            <span class="ml-[5px]">:</span>
            <BSpinner
              v-if="loadingRequested"
              small
              class="ml-1"
              variant="primary"
            />
            <div
              v-else
              class="text-[#FBA63C] text-xl font-semibold ml-[5px]"
            >
              {{ IDR(requested) }}
            </div>
          </div>
        </BCard>
      </BCol>
    </div>
    <div class="flex justify-end mt-4 items-center gap-[10px]">
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'center'"
        class="withdrawal-date-range"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)
                "
                style="color: #828282 !important"
              >
                2 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                "
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(komshipDate) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Semua
              </span>

              <span
                v-else
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                {{ DDMMYYYY(picker.startDate) }} -
                {{ DDMMYYYY(picker.endDate) }}
              </span>
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
              alt="Komerce"
            >
          </div>
        </template>
      </DateRangePicker>
      <BFormSelect
        v-model="statusFilter"
        :options="statusOptions"
        placeholder="Pilih Status"
        class="max-w-[200px]"
      />
      <BFormInput
        v-model="search"
        placeholder="Cari nama, email, no.rek"
        class="max-w-[200px] min-h-[38px]"
        @input="searchData"
      />
      <button
        class="py-[7px] rounded-[8px] px-[20px] border-2 border-[#F95031] outline-none text-semibold text-[#F95031] hover:bg-[#F95031] hover:text-white"
        @click="resetButton"
      >
        Reset
      </button>
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        hover
        selectable
        select-mode="single"
        @row-selected="handleToDetail"
      >
        <template #cell(created_at)="data">
          <span class="font-medium">{{
            DAY_MONTH_YEAR(data.item.created_at)
          }}</span>
          <br>
          <span
            style="color: #828282"
            class="text-sm"
          >
            {{ TIME(data.item.created_at) }}
          </span>
        </template>
        <template #cell(partner_name)="data">
          <span class="font-semibold">{{ data.item.partner_name }}</span>
          <br>
          <span>{{ data.item.partner_email }}</span>
        </template>
        <template #cell(nominal)="data">
          <span class="font-semibold">{{ IDR(data.item.nominal) }}</span>
        </template>
        <template #cell(bank_name)="data">
          <span v-if="data.item.bank_name">{{ data.item.bank_name }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(status)="data">
          <span
            :class="classStatus(data.item.status)"
            style="border-radius: 20px; padding: 0 10px; min-width: 100px !important"
          >
            {{ lableStatus(data.item.status) }}
          </span>
        </template>
        <template #cell(bank_account_no)="data">
          <div v-if="data.item.bank_account_no !== null">
            <span>{{ data.item.bank_account_no }}</span>
            <br>
            <span
              style="color: #222222"
              class="text-sm"
            >
              {{ data.item.bank_account_name }}
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </BTable>
      <BRow>
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div
            class="
              bg-light
              d-flex
              justify-content-center
              align-items-center
              p-50
              rounded
            "
          >
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BCard,
  BTable,
  BOverlay,
  BPagination,
  BButton,
  BRow,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  komshipDate,
  last2,
  last7,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY,
  LABELMONTH,
  DDMMYYYY,
  YEAR_MONTH_DAY,
  TIME,
  DAY_MONTH_YEAR,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { listWithdrawal, statusOptionsListWithdrawal } from './config'

export default {
  components: {
    BCard,
    BTable,
    BOverlay,
    BPagination,
    BButton,
    BRow,
    BCol,
    BFormSelect,
    DateRangePicker,
  },
  data() {
    return {
      title: 'Antrian Penarikan',
      isLoading: true,
      search: secureLocalStorage.get('searchWithdrawal')
        ? secureLocalStorage.get('searchWithdrawal')
        : '',
      items: [],
      fields: listWithdrawal,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [25, 50, 75, 100, 200],
      totalPerPage: 25,
      statusFilter: secureLocalStorage.get('statusWithdrawal')
        ? secureLocalStorage.get('statusWithdrawal')
        : null,
      statusOptions: statusOptionsListWithdrawal,
      dateRange: {
        startDate: secureLocalStorage.get('paramDateWithdrawalStartDate')
          ? secureLocalStorage.get('paramDateWithdrawalStartDate')
          : komshipDate,
        endDate: secureLocalStorage.get('paramDateWithdrawalEndDate')
          ? secureLocalStorage.get('paramDateWithdrawalEndDate')
          : today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari Terakhir': [last7, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Semua ': [komshipDate, today],
      },
      today,
      last2,
      last7,
      DDMMYYYY,
      lastDateOfMonth,
      komshipDate,
      firstDateOfMonth,
      YEAR_MONTH_DAY,
      IDR,
      TIME,
      DAY_MONTH_YEAR,
      balance: 0,
      requested: 0,
      loadingBalance: true,
      loadingRequested: true,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getListWhitdrawal()
      },
    },
    statusFilter: {
      handler(value) {
        if (value === '') {
          secureLocalStorage.set('statusWithdrawal', value)
          this.getListWhitdrawal()
          return
        }

        if (!value) return

        secureLocalStorage.set('statusWithdrawal', value)
        this.getListWhitdrawal()
      },
    },
    dateRange: {
      handler(value) {
        if (!value) return

        secureLocalStorage.set(
          'paramDateWithdrawalStartDate',
          YEAR_MONTH_DAY(value.startDate),
        )
        secureLocalStorage.set(
          'paramDateWithdrawalEndDate',
          YEAR_MONTH_DAY(value.endDate),
        )
        this.getListWhitdrawal()
      },
    },
  },
  mounted() {
    this.getListWhitdrawal()
    this.getDataXendit()
    this.getDataRequested()
  },
  methods: {
    resetButton() {
      this.search = ''
      this.statusFilter = null
      this.dateRange.startDate = YEAR_MONTH_DAY(komshipDate)
      this.dateRange.endDate = YEAR_MONTH_DAY(today)

      if (!this.statusFilter && !(this.dateRange.startDate === YEAR_MONTH_DAY(komshipDate) && (this.dateRange.endDate === YEAR_MONTH_DAY(today)))) {
        this.getListWhitdrawal()
      }
      this.getListWhitdrawal()
    },
    async getListWhitdrawal() {
      this.isLoading = true
      const params = {
        total_per_page: this.totalPerPage,
        page: this.currentPage,
        search: this.search,
        status: this.statusFilter,
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await this.$http
        .get('/v1/admin/withdrawal/list', { params })
        .then(res => {
          const { data } = res.data.data
          this.items = data
          this.totalRows = res.data.data.total
          this.isLoading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },

    async getDataXendit() {
      this.loadingBalance = true
      const url = '/v1/admin/withdrawal/balance/xendit'
      await this.$http.get(url).then(res => {
        const { data } = res.data
        this.balance = data
        this.loadingBalance = false
      })
    },

    async getDataRequested() {
      this.loadingRequested = true
      const url = '/v1/admin/withdrawal/balance/requested'
      await this.$http.get(url).then(res => {
        const { data } = res.data
        this.requested = data
        this.loadingRequested = false
      })
    },

    setPerPage(page) {
      this.totalPerPage = page
      this.getListWhitdrawal()
    },
    searchData: _.debounce(function search(text) {
      this.dateRange.startDate = YEAR_MONTH_DAY(this.komshipDate)
      this.dateRange.endDate = YEAR_MONTH_DAY(this.today)
      this.statusFilter = null
      this.getListWhitdrawal()
    }, 1000),
    lableStatus(value) {
      if (value === 'requested') return 'Request Penarikan'
      if (value === 'on_review') return 'Sedang Direview'
      if (value === 'completed') return 'Disetujui'
      if (value === 'rejected') return 'Ditolak'
      if (value === 'in_process') return 'Diproses'
      return ''
    },
    classStatus(value) {
      if (value === 'requested') return 'requestedList'
      if (value === 'on_review') return 'on_reviewList'
      if (value === 'completed') return 'completedList'
      if (value === 'rejected') return 'rejectedList'
      if (value === 'in_process') return 'in_processList'
      return ''
    },
    handleToDetail(value) {
      const { withdrawal_id } = value[0]
      this.$router.push({
        // eslint-disable-next-line camelcase
        path: `/pencairan/${withdrawal_id}`,
      })
    },
    handleRefreshBalance() {
      this.getDataXendit()
      this.getDataRequested()
    },
  },
}
</script>

<style scoped lang="scss">
@import './Pencairan.scss';
</style>
